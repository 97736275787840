 .tv_chart_container{
   position: relative;
  overflow: hidden;
  width: 100%;
   /* 4:3 Aspect Ratio */
} #responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
@media only screen and (min-width: 968px) {
 
  .Myburger{
    margin-top:1.5rem;
  }
}
.Myburger{
    margin-top:1rem;
  }
.textColor{
  color:#def1ff;
}
.cardHeader{
  background: rgb(0,115,193);
  background: linear-gradient(90deg, rgba(0,115,193,1) 0%, rgba(22,24,26,1) 100%) 
}
.imgbgblue{background: #0089ff94;border-radius: 50%;}
.bgpurple{background-color: rgb(22 22 22 / 21%);}
.bgblacktransparent{background-color: rgb(0 0 0 / 70%); 
   -webkit-box-shadow: 0px 3px 10px 5px #000000;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 10px 5px #000000;  /* Firefox 3.5 - 3.6 */
  box-shadow:    0px 3px 10px 5px #000000;}
  .bgblacksemitransparent{background-color: rgb(0 0 0 / 50%); 
    -webkit-box-shadow: 0px 3px 10px 5px #000000;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
   -moz-box-shadow: 0px 3px 10px 5px #000000;  /* Firefox 3.5 - 3.6 */
   box-shadow:    0px 3px 10px 5px #000000;}
   .bgblackmodal{background-color: rgb(0 0 0 / 80%); 
    -webkit-box-shadow: 0px 3px 10px 5px #000000;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
   -moz-box-shadow: 0px 3px 10px 5px #000000;  /* Firefox 3.5 - 3.6 */
   box-shadow:    0px 3px 10px 5px #000000;}
 .neon {
  color: #ba01ff;
  text-decoration: none;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  text-shadow: 0 0 5px #b41bbb, 0 0 10px #ff00de, 0 0 15px #520671, 0 0 20px #5521b5, 0 0 35px #b501ff, 0 0 40px #b501ff, 0 0 50px #b501ff, 0 0 75px #b501ff;
}
.resize {
  border: 1px solid black; 
  overflow:auto;
  resize: both;
}
.rt-tr-group{background-color: rgb(0 0 0 / 2%); 
  -webkit-box-shadow: 0px 3px 10px 5px #000000;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
 -moz-box-shadow: 0px 3px 10px 5px #000000;  /* Firefox 3.5 - 3.6 */
 box-shadow:    0px 3px 10px 5px #000000;}
 .rt-tr-group:first-child{background-color: rgb(0 0 0 / 30%); 
  -webkit-box-shadow: 0px 3px 10px 5px #000000;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
 -moz-box-shadow: 0 0 2.5px 1px hsl(0deg 0% 100% / 24%), 0 0 8px 5px rgb(27 52 179 / 83%), 0 0 12px 7px rgb(0 255 255 / 32%);  /* Firefox 3.5 - 3.6 */
 box-shadow: 0 0 2.5px 1px hsl(0deg 0% 100% / 24%), 0 0 8px 5px rgb(27 52 179 / 83%), 0 0 12px 7px rgb(0 255 255 / 32%);
}
 .rt-tr-group:nth-of-type(even){background-color: rgb(0 0 0 / 50%); 
   -webkit-box-shadow: 0px 3px 10px 5px #000000;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 0px 3px 10px 5px #000000;  /* Firefox 3.5 - 3.6 */
  box-shadow:    0px 3px 10px 5px #000000;}
  .bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 196, 141, var(--tw-bg-opacity));
}
