div.loading-user-card{
    margin-top: 40px;
    grid-row: 3/4;
    grid-column: 1 / 4;
}

.user-profile{
    grid-row: 3/4;
    grid-column: 1 / 4
}
div.user-profile-grid{
    margin: 52px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: repeat(4, auto);
    grid-column-gap: 3rem;
    grid-row-gap: 10px;
    align-items: center;
}
div.user-bio{
    grid-row: 3/4;
}
div.user-bio p {
    text-align: left;
    font-size: 16px;
    font-family: unset;
}
div.user-img{
    grid-row: 1/4;
    grid-column: 1 / 2;
}
div.profile-user-settings {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    display: flex;
    max-width: 500px;
}
h1.user-name{
    flex: 1;  
    text-align: left;
    
}
a.btn-visit{
    flex: 2;
    /* width: 50px; */
}
div.user-stats{
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    
}
div.user-stats ul{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    
}
div.user-stats ul li{
    margin-bottom: 10px;
    margin-right: 35px;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    position: relative;
	box-sizing: border-box;
	transition: all 200ms ease;
    position: relative;
    padding: 2px;

    /* transition: all ease-in-out .2s; */
}
div.user-stats ul li:hover {
	background: rgba(0,0,0,0);
    color: #3a7999;
    border-bottom: 3px solid #3a7999
	/* box-shadow: inset 0 0 0 3px #3a7999; */
}
h1{
    line-height: 37.05px;
    font-family: "Open Sans", Arial, sans-serif;
    color: #262626;
    font-weight: 300;
}
.btn-visit {
    display: block;
    /* margin: 0 auto 50px; */
    background: #fff;
    border: 1px solid #9e9e9e;
    color: #707070;
    padding: 10px 40px;
    overflow: hidden;
    max-width: 100px;
    color: #262626; 
    text-decoration: none; 
    margin-top: 2px;
    margin-left: 15px;
    align-self: center
}
.profile-stats{
    grid-row: 1 /2 ;
    grid-column: 2 / 3;
}
.user-stats ul {
    display: flex;
    text-align: center;
    padding: 1.2rem 0;
    border-top: 0.1rem solid #dadada;
    border-bottom: 0.1rem solid #dadada;
}

img.user-img{
    /* margin-left: 20px;
    margin-bottom: 1em; */
    width: 152px;
    height: 152px;
    border-radius: 50%;
    /* max-width: 50%; */
}
a.btn-visit:hover{
    transition: background 0.5s ease;
    background-color: #3a7999;
    color: #fff;
}

/* smarphone design responsive design */
@media only screen and (max-width: 650px) {

    .user-profile {
        grid-row: 3/4;
        grid-column: 2 / 3;
    }
    div.user-img {
        grid-row: 1/2;
        grid-column: 1 / 2;
    }
    img.user-img {
        width: 102px;
        height: 102px;
    }
    h1.user-name {
        text-align: left;
        font-size: 25px;
        display: inline-block;
        font-weight: 300;
        line-height: unset;
        line-height: 26px;
    }
    .user-stats ul{
        justify-content: space-around;
    }
    div.user-bio{
        grid-column: 1 / 4;
    }
    div.profile-user-settings{
        grid-row: 1 / 2;
        grid-column: 2 / 3;
        display: grid;
        grid-template-columns: 1f 1fr;
        grid-auto-rows: 1fr 1fr;
    }
    div.profile-user-settings h3{
        grid-column: 1 / 2
    }
    .btn-visit{
        grid-column: 1 / 2;
    
        padding: 4px;
        width: 100px;
        margin-left: 0;
        margin-top: 15px;
    }
}
@media only screen and (max-width: 450px) {
    div.user-profile-grid{
        margin: 52px 20px 20px 20px;
    }
    div.data-wrapper ul li h3 {
        font-size: 16px;
    
    }

}


