div.user-data-loading{
    grid-row: 4/5;
    grid-column: 2 / 3;

    position: relative;
    margin: 30px 0 0 80px;
    width: 14px;
    height: 14px;

}
div.user-data-grid{
    grid-row: 4 / 5;
    grid-column: 2 / 3;
}
div.data-wrapper ul{
  list-style: none;
  margin-top: 40px;
  text-align: left;
  
}
div.data-wrapper ul li{
    padding: 5px;
    z-index: -2;
    box-sizing: border-box;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
    margin: 20px;
    display: flex;
    align-items: center;
    margin-left: 0;
    /* justify-content: space-between; */
}
div.data-wrapper ul li.repos-list{
    justify-content: space-between;
}
.user-data-img{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    
}
h3 .user-data-login{
    margin-left: 12px;
    color: black;
    text-decoration: none;
}

div.user-repos-stars {
    margin-left: 15px;
    border: 1px solid black;
    padding: 5px;
}
div.data-wrapper ul li h3{
    flex: 1;
    align-self: flex-start;
}
a.user-repos-name{
    text-decoration: none;
    color: #323648;
} 
/* smarphone design responsive design */
@media only screen and (max-width: 650px) {

    div.user-data-loading{
        grid-row: 4/5;
        grid-column: 1 / 3;
    }
    div.user-stats {
        grid-row: 2 / 3;
        grid-column: 1 / 4;
    }
    div.user-data-grid {
        grid-row: 4 / 5;
        grid-column: 1 / 4;
    }

}